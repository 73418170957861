<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-flex justify-content-between align-items-start">
            <div>
              <h3>Payout Request Categories</h3>
            </div>
            <b-btn variant="primary" v-b-modal.payout-request-category-form>
              <i class="fa fa-plus mr-1"></i>
              Create New Category
            </b-btn>
          </div>

          <div class="widget-content widget-content-area">
            <div class="table-responsive">
              <table class="table table-bordered w-100">
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Sub-categories</th>
                    <th>Created Date</th>
                    <th>Last Modified Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(category, index) in payoutRequestCategories"
                    :key="category.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ category.name }}</td>
                    <td>{{ category.description }}</td>
                    <td>
                      <a
                        href="javascript:void(0);"
                        @click="openSubCategories(category)"
                      >
                        {{ category.payout_request_sub_categories_count }}
                      </a>
                    </td>
                    <td>{{ formatDateTime(category.created_at) }}</td>
                    <td>{{ formatDateTime(category.updated_at) }}</td>
                    <td>
                      <b-button-group size="sm">
                        <b-btn
                          variant="primary"
                          @click="editPayoutRequestCategory(category)"
                          v-if="category.deleted_at == null"
                        >
                          <b-icon icon="pencil"></b-icon>
                        </b-btn>
                        <b-btn
                          variant="danger"
                          @click="deletePayoutRequestCategory(category.id)"
                          v-if="category.deleted_at == null"
                        >
                          <b-icon icon="trash"></b-icon>
                        </b-btn>
                        <b-btn
                          variant="success"
                          @click="restorePayoutRequestCategory(category.id)"
                          v-else
                        >
                          <b-icon icon="recycle"></b-icon>
                        </b-btn>
                      </b-button-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Create/Update modal -->
      <b-modal
        id="payout-request-category-form"
        :title="(editing ? 'Update' : 'Create') + ' a payout request category'"
        centered
        @hide="payoutRequestCategoryForm.reset()"
      >
        <form
          method="POST"
          @submit.prevent="
            editing
              ? updatePayoutRequestCategory()
              : createPayoutRequestCategory()
          "
          @keydown="payoutRequestCategoryForm.onKeydown($event)"
        >
          <AlertError :form="payoutRequestCategoryForm" />

          <b-form-group
            description=""
            label="Name *"
            label-for="name"
            :invalid-feedback="payoutRequestCategoryForm.errors.get('name')"
            :state="!payoutRequestCategoryForm.errors.has('name')"
          >
            <b-form-input
              id="name"
              v-model="payoutRequestCategoryForm.name"
              placeholder="Enter the name of the category"
              type="text"
              :state="
                payoutRequestCategoryForm.errors.has('name') ? false : null
              "
              trim
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            description=""
            label="Description"
            label-for="description"
            :invalid-feedback="
              payoutRequestCategoryForm.errors.get('description')
            "
            :state="!payoutRequestCategoryForm.errors.has('description')"
          >
            <b-form-textarea
              id="description"
              v-model="payoutRequestCategoryForm.description"
              placeholder="Enter the description of the category"
              type="text"
              :state="
                payoutRequestCategoryForm.errors.has('description')
                  ? false
                  : null
              "
              rows="2"
            ></b-form-textarea>
          </b-form-group>
        </form>

        <template #modal-footer="{ cancel }">
          <b-button size="sm" variant="danger" @click="cancel()">
            Cancel
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              editing
                ? updatePayoutRequestCategory()
                : createPayoutRequestCategory()
            "
            :disabled="payoutRequestCategoryForm.busy"
          >
            <b-spinner
              label="Loading"
              v-if="payoutRequestCategoryForm.busy"
              small
            ></b-spinner>
            {{ editing ? "Update" : "Create" }}
          </b-button>
        </template>
      </b-modal>

      <!-- Sub-categories modal -->
      <b-modal
        id="payout-request-sub-category-modal"
        size="xl"
        @hide="payoutRequestSubCategoryForm.reset()"
        centered
        hide-footer
      >
        <template #modal-header="{ close }">
          <h5>
            Sub-categories for
            <code>
              {{ payoutRequestCategory ? payoutRequestCategory.name : "" }}
            </code>
          </h5>
          <div>
            <b-btn
              variant="outline-secondary"
              @click="openSubCategoryForm = !openSubCategoryForm"
              size="sm"
            >
              {{
                !openSubCategoryForm
                  ? editingSubCategory
                    ? "Update"
                    : "Create"
                  : "Close"
              }}
              Sub-category Form
            </b-btn>
            <button class="close" aria-label="Close" @click="close()">x</button>
          </div>
        </template>

        <form
          method="POST"
          @submit.prevent="
            editingSubCategory
              ? updatePayoutRequestSubCategory()
              : createPayoutRequestSubCategory()
          "
          @keydown="payoutRequestSubCategoryForm.onKeydown($event)"
          v-if="openSubCategoryForm"
          class="mb-3 pb-3 border-bottom"
        >
          <AlertError :form="payoutRequestSubCategoryForm" />

          <b-form-group
            description=""
            label="Name *"
            label-for="name"
            :invalid-feedback="payoutRequestSubCategoryForm.errors.get('name')"
            :state="!payoutRequestSubCategoryForm.errors.has('name')"
          >
            <b-form-input
              id="name"
              v-model="payoutRequestSubCategoryForm.name"
              placeholder="Enter the name of the sub-category"
              type="text"
              :state="
                payoutRequestSubCategoryForm.errors.has('name') ? false : null
              "
              trim
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            description=""
            label="Description"
            label-for="description"
            :invalid-feedback="
              payoutRequestSubCategoryForm.errors.get('description')
            "
            :state="!payoutRequestSubCategoryForm.errors.has('description')"
          >
            <b-form-textarea
              id="description"
              v-model="payoutRequestSubCategoryForm.description"
              placeholder="Enter the description of the sub-category"
              type="text"
              :state="
                payoutRequestSubCategoryForm.errors.has('description')
                  ? false
                  : null
              "
              rows="2"
            ></b-form-textarea>
          </b-form-group>
          <b-button
            type="submit"
            size="sm"
            variant="success"
            :disabled="payoutRequestSubCategoryForm.busy"
          >
            <b-spinner
              label="Loading"
              v-if="payoutRequestSubCategoryForm.busy"
              small
            ></b-spinner>
            {{ editingSubCategory ? "Update" : "Create" }}
          </b-button>
        </form>

        <div class="table-responsive">
          <table class="table table-bordered w-100">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Description</th>
                <th>Payout Requests</th>
                <th>Created Date</th>
                <th>Last Modified Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(category, index) in payoutRequestSubCategories"
                :key="category.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ category.name }}</td>
                <td>{{ category.description }}</td>
                <td>{{ category.payout_requests_count }}</td>
                <td>{{ formatDateTime(category.created_at) }}</td>
                <td>{{ formatDateTime(category.updated_at) }}</td>
                <td>
                  <b-button-group size="sm">
                    <b-btn
                      variant="primary"
                      @click="editPayoutRequestSubCategory(category)"
                      v-if="category.deleted_at == null"
                    >
                      <b-icon icon="pencil"></b-icon>
                    </b-btn>
                    <b-btn
                      variant="danger"
                      @click="deletePayoutRequestSubCategory(category.id)"
                      v-if="category.deleted_at == null"
                    >
                      <b-icon icon="trash"></b-icon>
                    </b-btn>
                    <b-btn
                      variant="success"
                      @click="restorePayoutRequestSubCategory(category.id)"
                      v-else
                    >
                      <b-icon icon="recycle"></b-icon>
                    </b-btn>
                  </b-button-group>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import roles from "@/mixins/roles";

export default {
  name: "AllPayoutRequestCategories",
  mixins: [roles],
  data() {
    return {
      records: null,
      payoutRequestCategoryForm: this.$Form({
        name: "",
        description: "",
      }),
      payoutRequestCategory: null,
      editing: false,
      payoutRequestCategories: [],
      payoutRequestSubCategoryForm: this.$Form({
        payout_request_category_id: "",
        name: "",
        description: "",
      }),
      payoutRequestSubCategories: [],
      payoutRequestSubCategory: null,
      openSubCategoryForm: false,
      editingSubCategory: false,
    };
  },
  watch: {
    openSubCategoryForm(val) {
      if (!val) {
        this.editingSubCategory = false;
        this.resetPayoutRequestSubCategoryForm();
      }
    },
  },
  methods: {
    fetchRecords() {
      http
        .get(
          endpoints.VIEW_ALL_PAYOUT_REQUEST_CATEGORIES +
            "?filter[trashed]=with&include=payoutRequestSubCategoriesCount"
        )
        .then((response) => {
          this.payoutRequestCategories = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    createPayoutRequestCategory() {
      this.payoutRequestCategoryForm
        .post(endpoints.CREATE_PAYOUT_REQUEST_CATEGORY)
        .then((response) => {
          this.$toast.success(response);
          this.$bvModal.hide("payout-request-category-form");
          this.fetchRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    editPayoutRequestCategory(payoutRequestCategory) {
      this.editing = true;
      this.payoutRequestCategory = payoutRequestCategory;
      this.payoutRequestCategoryForm.fill(payoutRequestCategory);
      this.$bvModal.show("payout-request-category-form");
    },
    updatePayoutRequestCategory() {
      this.payoutRequestCategoryForm
        .put(
          endpoints.UPDATE_PAYOUT_REQUEST_CATEGORY.replace(
            ":id",
            this.payoutRequestCategory.id
          )
        )
        .then((response) => {
          this.$toast.success(response);
          this.$bvModal.hide("payout-request-category-form");
          this.fetchRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    deletePayoutRequestCategory(id) {
      http
        .delete(endpoints.DELETE_PAYOUT_REQUEST_CATEGORY.replace(":id", id))
        .then(() => {
          this.$toast.success("Payout request category deleted successfully");
          this.fetchRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    restorePayoutRequestCategory(id) {
      http
        .patch(endpoints.RESTORE_PAYOUT_REQUEST_CATEGORY.replace(":id", id))
        .then(() => {
          this.$toast.success("Payout request category restored successfully");
          this.fetchRecords();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    openSubCategories(payoutRequestCategory) {
      this.payoutRequestCategory = payoutRequestCategory;
      this.payoutRequestSubCategoryForm.payout_request_category_id =
        payoutRequestCategory.id;

      this.fetchSubCategories(payoutRequestCategory.id, true);
    },
    fetchSubCategories(payoutRequestCategoryId, openModal = false) {
      http
        .get(
          endpoints.VIEW_ALL_PAYOUT_REQUEST_SUB_CATEGORIES +
            `?filter[trashed]=with&filter[payout_request_category_id]=${payoutRequestCategoryId}&include=payoutRequestsCount`
        )
        .then((response) => {
          this.payoutRequestSubCategories = response;

          if (openModal) {
            this.$bvModal.show("payout-request-sub-category-modal");
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    createPayoutRequestSubCategory() {
      this.payoutRequestSubCategoryForm
        .post(endpoints.CREATE_PAYOUT_REQUEST_SUB_CATEGORY)
        .then((response) => {
          this.$toast.success(response);
          this.fetchSubCategories(this.payoutRequestCategory.id);
          this.resetPayoutRequestSubCategoryForm();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    editPayoutRequestSubCategory(payoutRequestSubCategory) {
      this.editingSubCategory = true;
      this.openSubCategoryForm = true;
      this.payoutRequestSubCategory = payoutRequestSubCategory;
      this.payoutRequestSubCategoryForm.fill(payoutRequestSubCategory);
    },
    updatePayoutRequestSubCategory() {
      this.payoutRequestSubCategoryForm
        .put(
          endpoints.UPDATE_PAYOUT_REQUEST_SUB_CATEGORY.replace(
            ":id",
            this.payoutRequestSubCategory.id
          )
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchSubCategories(this.payoutRequestCategory.id);
          this.resetPayoutRequestSubCategoryForm();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    deletePayoutRequestSubCategory(id) {
      http
        .delete(endpoints.DELETE_PAYOUT_REQUEST_SUB_CATEGORY.replace(":id", id))
        .then(() => {
          this.$toast.success(
            "Payout request sub-category deleted successfully"
          );
          this.fetchSubCategories(this.payoutRequestCategory.id);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    restorePayoutRequestSubCategory(id) {
      http
        .patch(endpoints.RESTORE_PAYOUT_REQUEST_SUB_CATEGORY.replace(":id", id))
        .then(() => {
          this.$toast.success(
            "Payout request sub-category restored successfully"
          );
          this.fetchSubCategories(this.payoutRequestCategory.id);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    resetPayoutRequestSubCategoryForm() {
      this.payoutRequestSubCategoryForm.reset();
      this.payoutRequestSubCategoryForm.payout_request_category_id =
        this.payoutRequestCategory.id;
    },
  },
  mounted() {
    this.fetchRecords();
  },
};
</script>
